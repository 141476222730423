import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import Banner from "../containers/Banner"
import Breadcrumbs from "../containers/Breadcrumbs"
import Certifications from "../containers/Certifications"
import Consultation from "../containers/Consultation"
import ConsultationAlt from "../containers/ConsultationAlt"
import Layout from "../containers/Layout"
import More from "../containers/More"
import SEO from "../components/SEO"
import Services from "../containers/Services"
import ServicesIntro from "../containers/ServicesIntro"
import { ContentWrapper } from "../containers/alpacked.style"

const ConsultingPage = ({ data, location }) => {
  const {
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
    body,
  } = data.prismicConsultingPage.data

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/consulting">
        <ContentWrapper>
          <Banner
            title={body[0].items[0].title.html}
            subtitle={body[0].items[0].subtitle}
            mainButtonText={body[0].items[0].button_a_title}
            mainButtonLink={body[0].items[0].button_a_link}
            secButtonText={body[0].items[0].button_b_title}
            secButtonLink={body[0].items[0].button_b_link}
            image={body[0].items[0].image.url}
          />
          <Breadcrumbs page="consulting" />
          <ServicesIntro content={body[1]} />
          <Services cloud={body[2]} devOps={body[3]} url="consulting" />
          <Certifications content={body[4]} />
          <ConsultationAlt pathname="/consulting" />
          <More content={body[5]} />
          <Consultation greyBg pathname="/consulting" />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withPreview(ConsultingPage)

export const ConsultingContentPage = graphql`
  query ConsultingPageContent {
    prismicConsultingPage {
      data {
        seo_description {
          text
        }
        seo_short_description {
          text
        }
        seo_title {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        body {
          ... on PrismicConsultingPageBodyBanner {
            id
            items {
              subtitle
              button_a_link
              button_a_title
              button_b_link
              button_b_title
              title {
                html
              }
              image {
                url
              }
            }
          }
          ... on PrismicConsultingPageBodyIntro {
            id
            primary {
              title {
                html
              }
              title_colored {
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicConsultingPageBodyMore {
            id
            primary {
              title {
                html
              }
              title_colored1 {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              column_a {
                html
              }
              column_b {
                html
              }
            }
          }
          ... on PrismicConsultingPageBodyCertifications {
            id
            primary {
              title {
                html
              }
              title_colored {
                html
              }
              description {
                html
              }
            }
            items {
              image {
                url
              }
            }
          }
          ... on PrismicConsultingPageBodyServices {
            id
            items {
              link {
                uid
                document {
                  ... on PrismicConsulting {
                    id
                    data {
                      category
                      title {
                        text
                      }
                    }
                  }
                }
              }
              icon {
                url
              }
            }
            primary {
              title {
                html
              }
            }
          }
        }
      }
    }
  }
`
